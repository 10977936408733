import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HomepageComponent } from './homepage.component';
import { HeroComponent } from '../components/hero/hero.component';
import { StoriesComponent } from '../components/stories/stories.component';
import {
    GrayoutService,
    HeroImageModule,
    LoadingSpinnerModule,
    QuickQuoteModule,
    QuickQuoteService,
    EventFilterModule,
    WhatsIncludedModalModule,
    WindowRef
} from '@wdpr/dcl-ui-components-library';
import { CrosspromoComponent } from '../components/stories/crosspromo/crosspromo.component';
import { FastCrosspromoComponent } from '../components/stories/fast-crosspromo/fast-crosspromo.component';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '@app/shared/components/components.module';
import { VpgComponent } from '../components/stories/vpg/vpg.component';
import { StoryBlockItemComponent } from '../components/stories/story-block-item/story-block-item.component';
import { AwardsComponent } from '../components/stories/awards/awards.component';
import { CountdownClockComponent } from '../components/countdown-clock/countdown-clock.component';
import { RevenueComponent } from '../components/stories/revenue/revenue.component';
import { DestinationComponent } from '../components/stories/destination/destination.component';
import { FastDestinationComponent } from '../components/stories/fast-destination/fast-destination.component';
import { StoryLinkItemComponent } from '../components/stories/story-link-item/story-link-item.component';
import { TranslateSizeClassPipe } from '@app/features/homepage/components/stories/shared/translate-size-class.pipe';
import { StoryFadeInDirective } from '../components/stories/shared/story-fade-in.directive';
import { DeepLinkDirective } from '../components/stories/shared/deep-link.directive';
import { TrackSeenDirective } from '../components/stories/shared/track-seen.directive';
import { DvicBannerComponent } from '../components/stories/dvic-banner/dvic-banner.component';
import { AbsTrimmerPipe } from '@app/pipes/abs-trimmer.pipe';
import { HkdlQuickQuoteComponent } from '@app/features/homepage/components/hkdl-quick-quote/hkdl-quick-quote.component';
import { HkdlQuickQuoteModule } from '@wdpr/hkdl-quickquote';
import { FeaturedProductComponent } from '../components/stories/featured-product/featured-product.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    HomepageComponent,
    HeroComponent,
    StoriesComponent,
    CrosspromoComponent,
    FastCrosspromoComponent,
    VpgComponent,
    StoryBlockItemComponent,
    AwardsComponent,
    CountdownClockComponent,
    RevenueComponent,
    DestinationComponent,
    FastDestinationComponent,
    StoryLinkItemComponent,
    TranslateSizeClassPipe,
    StoryFadeInDirective,
    DeepLinkDirective,
    TrackSeenDirective,
    DvicBannerComponent,
    AbsTrimmerPipe,
    HkdlQuickQuoteComponent,
    FeaturedProductComponent
  ],
  exports: [
    HomepageComponent
  ],
  imports: [
      QuickQuoteModule,
      CommonModule,
      ComponentsModule,
      HkdlQuickQuoteModule,
      LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    FeaturedProductComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: []
})
export class HomepageModule { }
