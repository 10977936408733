import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { HotelsQuickQuoteConstraints, } from '@wdpr/hkdl-quickquote';
import { TicketsQuickQuoteConstraints } from '@wdpr/hkdl-quickquote';
import { GlobalConfig } from '@wdpr/hkdl-quickquote';
import { Content } from '@wdpr/hkdl-quickquote';
import { HotelsValidationErrors } from '@wdpr/hkdl-quickquote';
import { TicketsValidationErrors } from '@wdpr/hkdl-quickquote';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import { get } from 'lodash';

@Component({
    selector: 'pixie-hkdl-quick-quote',
    templateUrl: './hkdl-quick-quote.component.html',
    styleUrls: ['./hkdl-quick-quote.component.scss']
})
export class HkdlQuickQuoteComponent implements OnInit {

    /**
     * Quick Quote Configuration
     */
    @Input() config;

    /**
     * Resource Bundle from D-scribe
     */
    @Input() resourceBundle;

    /**
     * Hotels Quick QUote Form Constraints
     */
    public hotelsConstraints: HotelsQuickQuoteConstraints;

    /**
     * Tickets QUick Qote Form Configurations
     */
    public ticketConstraints: TicketsQuickQuoteConstraints;

    /**
     * Global Configurations
     */
    public globalConfigs: GlobalConfig;

    /**
     * Quick Quote content
     */
    public content: Content;

    /**
     * Hotels flow Validation Errors
     */
    public hotelsValidationErrors: HotelsValidationErrors;

    /**
     * Tickets flow Validation Errors
     */
    public ticketsValidationErrors: TicketsValidationErrors;

    /**
     * Boolean flag to determine if all tehe initializations are done
     */
    public isDataReady: boolean;


    public constructor(
        public loggerService: LoggerService,
        private analyticsService: HomepageAnalyticsService
    ) {
        this.isDataReady = false;
    }

    public ngOnInit() {
        this.buildHotelsConstraints(this.config);
        this.buildTicketConstraints(this.config);
        this.buildGlobalConfigs(this.config);
        this.buildContent(this.resourceBundle);
        this.isDataReady = true;
    }

    /**
     * Build Hotels QQ Form Constraints
     */
    public buildHotelsConstraints(config) {
        const constraint = config.constraints;
        const qqForm = config.qqForm;
        const qqVendoConfig = config.qqVendoConfig;
        const resortKeys = Object.keys(qqForm.constraints.resort);
        const resorts = resortKeys.map((key) => ({
            key: key,
            value: this.resourceBundle[key.split(';')[0]]
        }));
        this.hotelsConstraints = {
            maxPartySize: Number(constraint.maxPartySize),
            maxNumberOfAdults: Number(constraint.maxNumberOfAdults),
            maxNumberOfChildren: Number(constraint.maxNumberOfChildren),
            defaultLengthOfStay: Number(constraint.defaultLengthOfStay),
            minLengthOfStay: Number(constraint.minLengthOfStay),
            maxLengthOfStay: Number(constraint.maxLengthOfStay),
            maxCheckInDate: constraint.maxCheckinDate,
            minCheckInDays: qqVendoConfig.QQ_MIN_CHECKIN_DAYS,
            resorts: resorts
        };
    }

    /**
     * Build Tickets QQ Form Constraints
     */
    public buildTicketConstraints(config) {
        const constraints = config.constraints;
        const elements = config.elements;
        const generalAdmission = elements.generalAdmission.options.multiOptions || elements.generalAdmission.multiOptions;
        const childrenConfig = elements.child || elements.children;
        const children = childrenConfig.options.multiOptions;
        const tickets = elements.tickets.options.multiOptions;
        const ticketsOptions = [];
        tickets.forEach((key, index) => {
            ticketsOptions.push({
                key: (index + 1).toString(),
                value: key
            });
        });

        this.ticketConstraints = {
            maxTickets: constraints.maxTickets,
            maxNumberOfAdults: Number(generalAdmission[generalAdmission.length - 1]),
            maxNumberOfChildren: Number(children[children.length - 1]),
            tickets: ticketsOptions
        };
    }

    /**
     * Build Global Configurations
     */
    public buildGlobalConfigs(config) {
        this.globalConfigs = {
            csrfToken: config._info.csrfToken,
            displayDateFormat: config.qqFormOptions.momentDateFormat,
            localeBaseUrl:  config._info.registry.localeBaseUrl
        };
    }

    /**
     * Build content
     */
    public buildContent(resourceBundle): void {
        this.content = resourceBundle.content;
        this.hotelsValidationErrors = resourceBundle.hotelErrors;
        this.ticketsValidationErrors = resourceBundle.ticketErrors;
    }

    /**
     * Hotels form submisson event
     */
    public hotelFormSubmission(event) {
        const configKey = 'analytics.resortAnalyticsLinkId';
        const defaultValue = 'HKDL_ResortBookings_FindPricesButton';
        const trackingId = get(this.config, configKey, defaultValue);
        this.analyticsService.trackLink(trackingId);
    }

    /**
     * Tickets form submisson event
     */
     public ticketFormSubmission(event) {
        const configKey = 'analytics.ticketsAnalyticsLinkId';
        const defaultValue = 'HKDL_ParkTickets_FindPricesButton';
        const trackingId = get(this.config, configKey, defaultValue);
        this.analyticsService.trackLink(trackingId);
    }
}
